<!-- 赊货统计列表 -->
<template>
  <div class="Sales">
    <div
      style="
        height: 100%;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
      "
    >
      <div class="top" style="">
        <div class="flex">
          <el-input
            :placeholder="placeholder"
            v-model="searchKey"
            @input="inputChange"
            @keydown.enter.native="search"
          ></el-input>
        </div>
        <div class="flex" style="margin-top: 10px">
          <el-date-picker
            v-model="queryParam.StartTime"
            type="date"
            placeholder="开始日期"
            @change="startTimeChange"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-date-picker
            v-model="queryParam.EndTime"
            type="date"
            placeholder="结束日期"
            @change="endTimeChange"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="table" style="margin-top: 10px">
        <el-table
          :height="tableHeight"
          ref="queryForm"
          element-loading-text="拼命加载中"
          :data="tableData"
          v-loading="loading"
          v-el-table-infinite-scroll="scrollLoad"
          :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="scrollDisaled"
        >
          <el-table-column prop="cCusName" :label="label" align="left" fixed>
          </el-table-column>
          <el-table-column prop="ReceiveSum" label="赊货金额" align="left">
          </el-table-column>
        </el-table>
      </div>
      <!-- <el-talbe>
        <el-table-column prop="ReceiveSum" label="总计" align="left"> </el-table-column>
      </el-talbe> -->
    </div>
    <div
      style="
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 20px;
        background-color: #fff;
        display: flex;
      "
    >
      <div style="flex: 5">总计:</div>
      <div style="flex: 5">{{ toThousands(totalReceiveSum) }}</div>
    </div>
  </div>
</template>

<script>
import { timeStamp } from "console";
import { getMonthRange, toThousands, formatDate } from "../../../utils/Tools";
export default {
  data() {
    return {
      label: "客户",
      scrollDisaled: false,
      toThousands,
      tableHeight: 200,
      // 月份工具
      getMonthRange,
      loading: false,
      // 开始日期
      StartTime: "",
      // 结束日期
      EndTime: "",
      // 下拉选择数据
      options: [
        {
          value: "0",
          label: "客户",
        },
        {
          value: "1",
          label: "业务员",
        },
      ],
      // 收款统计列表数据
      tableData: [],
      selectKey: "客户",
      placeholder: "请输入客户进行查询",
      searchKey: "",
      date: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: [10, 30, 50],
      },
      sorter: {
        SortField: "ReceiveSum",
        SortType: "desc",
      },
      queryParam: {
        // 公司id
        CompanyId: "",
        // 客户名称
        Keywords: "",
        StartTime: "",
        EndTime: "",
      },
      totalReceiveSum: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
        document.documentElement.clientHeight -
        this.$refs.queryForm.$el.offsetHeight -
        10;
      this.tableHeight = height;
    });
  },
  created() {
    // 修改当前页面的标题
    document.title = "赊货统计";
    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }

    this.queryParam.CompanyId = this.$route.query.CompanyId;
    this.pagination.token = this.$route.query.token;
    // 获取当前年月日;
    let currentDate = new Date(); // 获取当前日期和时间
    currentDate.setMonth(currentDate.getMonth() - 1); // 将月份减去1
    // 获取当前一个月的前一个月份年月日
    let currentEndDate = getMonthRange(currentDate);
    let StartTime = formatDate("day");
    console.log("获取销售统计开始时间和结束时间", currentEndDate, StartTime);
    this.queryParam.StartTime = currentEndDate;
    this.queryParam.EndTime = StartTime;

    this.toGetList();
  },
  methods: {
    toGetList() {
      this.pagination.current = 1;
      this.scrollDisaled = true;
      this.tableData = [];
      // 获取赊货统计列表数据
      this.getCreditStatistics();
      // 获取赊货统计列表总金额统计接口
      this.GetCreditAllStatistics();
    },

    // 表格滚动加载
    scrollLoad() {
      // 监听表格滚动事件
      this.pagination.current++;
      if (
        this.pagination.current > 1 &&
        this.tableData.length == this.pagination.total
      ) {
        return;
      }
      // 获取赊货统计列表数据
      this.getCreditStatistics();
    },

    // 获取赊货统计列表总金额统计接口
    GetCreditAllStatistics() {
      this.$http
        .post("/BO/BIlling/GetCreditStatisticsTop", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            console.log("获取赊货统计总计=============", resJson);
            this.totalReceiveSum = resJson.Data.ReceiveSum;
          } else {
            this.loading = false;
          }
        });
    },
    // 时间选择
    startTimeChange(e) {
      console.log("开始时间=========", e);
      this.queryParam.StartTime = e;
      // 同时获取收款统计列表数据和收款统计总金额数据
      this.toGetList();
    },
    endTimeChange(e) {
      console.log("结束时间", e);
      this.queryParam.EndTime = e;
      // 同时获取收款统计列表数据和收款统计总金额数据
      this.toGetList();
    },
    // 搜索输入框输入
    inputChange(e) {
      console.log("搜索框输入", e);
      this.queryParam.Keywords = e;
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      this.getCreditStatistics();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getCreditStatistics();
    },
    // 搜索
    search() {
      // 同时获取收款统计列表数据和收款统计总金额数据
      this.toGetList();
    },
    // 获取赊货统计列表数据
    getCreditStatistics() {
      this.loading = true;
      this.$http
        .post("/BO/BIlling/GetCreditStatisticsTable", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
          ...this.sorter,
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.loading = false;
            // 没数据的时候，禁用 infinite-scroll-disabled 滚动事件
            if (resJson.Data.length == 0) {
              this.scrollDisaled = true;
              return;
            }

            this.scrollDisaled = false;

            if (resJson.Data) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
            if (resJson.Data.length != 0) {
              resJson.Data.forEach((item) => {
                // 金额格式化
                item.ReceiveSum = toThousands(item.ReceiveSum);
              });
            }
            this.tableData = this.tableData.concat(resJson.Data); // 分页请求的数据，追加到任务列表中

            if (
              this.pagination.current * this.pagination.pageSize >=
              this.pagination.total
            ) {
              return;
            }
          } else {
            this.loading = false;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-message {
  height: 100px !important;
  top: 100px !important;
  margin-top: -100px !important;
  z-index: 100000002 !important;
}

.flex {
  display: flex;
}
.Sales {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/App/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;
  .top {
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
  }
  .table {
  }
}
</style>
